<template>
    <div id="concurso-temp"> 
        <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
        <HeaderHome :name="user.fullname" />
        </div>

        <div class="body-concurso">
            <div class="header-concurso">
                <h5>¡GÁNATE UN KIT COMPLETO CIMARRONES!</h5>
                <h1>REGÍSTRATE Y <span class="transparent">PARTICIPA</span></h1>
                <a href="">
                    <h6>CONSULTA LAS BASES AQUÍ</h6>
                </a>
            </div>

            <form
            @submit.prevent="
                submit(
                  name_form,
                  phone_form,
                  email_form
                )
              "
            >
                <div class="form-body">
                    <img src="../assets/Kit.png" alt="kit" class="kit-img">
                    <div class="form-cont">
                        <h5 class="titulo-form">INGRESA TUS DATOS AQUÍ</h5>
                        <div class="field-cont">
                            <p>Nombre</p>
                            <input type="text" name="nombre" id="form-field" placeholder="Nombre" required v-model="name_form">
                        </div>
                        <div class="field-cont">
                            <p>Teléfono</p>
                            <input type="text" name="nombre" id="form-field" placeholder="Teléfono" required v-model="phone_form" 
                                minlength="10"
                                maxlength="10"
                                size="10"
                                pattern="\d*"
                            > 
                        </div>
                        <div class="field-cont">
                            <p>Correo</p>
                            <input type="email" name="nombre" id="form-field" placeholder="Correo" required v-model="email_form">
                        </div>

                        <div class="lastb">                        
                        <b-btn 
                            variant="outline-secondary"
                            class="l-button"
                            type="submit"
                            >REGÍSTRATE
                            
                        <img src="../assets/arrow2.png" alt="" class="">
                        </b-btn>
                        </div>
                        <div class="Mensaje_Error" v-if="this.status == 'error'">
                            <p>{{this.msg}}</p>
                        </div>
                        <div class="Mensaje_Suc" v-if="this.status == 'success'">
                            <p>{{this.msg}}</p>
                        </div>
                    </div>
                </div>
            </form>



            <div class="bases-body">
                <div class="bases-text">
                    <div class="titulo-bases">BA<br>SES</div>
                    <div class="bases-dots">
                        <div class="dotiteh">1</div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div class="bases-dots">
                        <div class="dotiteh">2</div>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                    </div>
                    <div class="bases-dots">
                        <div class="dotiteh">3</div>
                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    </div>
                    <div class="bases-dots">
                        <div class="dotiteh">4</div>
                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                    </div>
                </div>
                <img src="../assets/jugador-cima-2.png" alt="" class="jugadore">
                <img src="../assets/jugador-cima-3.png" alt="" class="jugadore-mob">
            </div>
        </div>

        <div id="footerSection">
        <Footer />
        </div>
    </div>
</template>
<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";

export default {
    data() {
    return {
      name_form: "",
      email_form: "",
      phone_form:"",
      status: "",
      msg: "",
    };
  },
  components: {
    HeaderHome,
    Footer,
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    
  },
  methods: {
        
    submit: async function (name, phone, email) {
       let user = {
        name: name,
        phone: phone,
        email: email
      };
              
          this.status=''
          this.msg=''
          
          
          let result = await this.$store.dispatch("main/addItem",  {option:'concurso', item: user});
          console.log(result)
          
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
            this.delStatus()
          }else{ // success
          console.log(result)
            this.msg="Registro exitoso"
            this.status="success"
            this.delStatus()
          }
          
     
    },
    delMsgs: function(){
       this.status=""
       this.msg=""
    },    
    delStatus: function () {
      setTimeout(() => this.delMsgs()
      ,3000);
    },


    },
}
</script>
<style >

.l-button{
    background: #B70D1D;
    color: white;
    border: none !important;
    border-radius: 0px !important;
}
.l-button:hover{
    background: #a00b1a;
}

.Mensaje_Error{
    margin-top: 1vw;
    background-color: #a00b1a;
    color: white;
    width: 28.5vw;
    height: 3vw;
    text-align: center;
    padding-top: 0.5vw;
    font-size: 1.2vw;
}
.Mensaje_Suc{
    margin-top: 1vw;
    background-color: #117221;
    color: white;
    width: 28.5vw;
    height: 3vw;
    text-align: center;
    padding-top: 0.5vw;
    font-size: 1.2vw;
}

#concurso-temp .body-concurso{
    background: #032a4b;
     background-image: url('../assets/fondiu-bases.png'), url('../assets/cuernifero.png');
     background-repeat: no-repeat,no-repeat;
    background-size: 100%,69.010vw;
    background-position-y: top,bottom;
    background-position-x: center, right;
}
#concurso-temp .header-concurso {
    height: 34.865vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11.25vw;
    cursor: default;
}#concurso-temp .header-concurso h5 {
    font-size: 1.563vw;
    line-height: 1.771vw;
    font-family: 'Rajdhani';
    color: white;
    margin-bottom: 2vw;
}#concurso-temp .header-concurso h1 {
    font-size: 7.292vw;
    line-height: 100.49%;
    text-align: center;
    color: #B70D1D;
    font-family: 'Rajdhani';
    width: 50%;
}#concurso-temp .header-concurso h1 span.transparent {
    -webkit-text-stroke: 0.1vw white;
    color: transparent;
}#concurso-temp .header-concurso a {
    color: white;
    font-size: 0.938vw;
    line-height: 1.094vw;
    font-family: 'Rajdhani';
    margin-top: 2.5vw;
}#concurso-temp .header-concurso a:hover {
    color: white!important;
}#concurso-temp .form-body {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}#concurso-temp .kit-img {
    width: 53.125vw;
    height: auto;
}#concurso-temp .titulo-form {
    font-size: 1.563vw;
    line-height: 1.771vw;
    font-family: 'Rajdhani';
    color: white;
    margin-bottom: 4.115vw;
}#concurso-temp #form-field {
    width: 27.5vw;
    height: 5.052vw;
    background: transparent;
    font-size: 1.042vw;
    font-family: 'Rajdhani';
    border-color: white;
    color: white;
    padding: 0 2.448vw;
}#concurso-temp .field-cont p {
    font-family: 'Rajdhani';
    color: white;
    margin-bottom: 0.5vw;
    font-size: 1.042vw;
}#concurso-temp .field-cont {
    margin-bottom: 2.135vw;
}#concurso-temp .lastb {
    width: 100%;
}#concurso-temp .l-button {
    width: 100%;
    padding: 1.823vw 0.95vw;
    text-align-last: justify;
}#concurso-temp .l-button:hover {
    background-position-x: 18.521vw; 
}#concurso-temp .bases-body {
    display: flex;
    justify-content: space-between;
    padding: 5.625vw 4.844vw 0 13.125vw;
}#concurso-temp .bases-text .titulo-bases {
    font-size: 10.417vw;
    line-height: 78%;
    color: white;
    font-family: 'Rajdhani';
    width: 21vw;
    margin-bottom: 5.365vw;
}#concurso-temp .bases-dots {
    display: flex;
    color: white;
    align-items: center;
    margin-bottom: 2.813vw;
    width: 31.542vw;
}#concurso-temp .dotiteh {
    background: #b70d1d;
    font-size: 1.458vw;
    font-family: 'Rajdhani';
    width: 3.75vw;
    height: 3.75vw;
    border-radius: 3.75vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.979vw;
}#concurso-temp .bases-dots p {
    font-size: 1.042vw;
    line-height: 1.771vw;
    font-family: 'Rajdhani';
    width: 28.021vw;
}#concurso-temp .jugadore {
    width: auto;
    height: 46.875vw;
    display: block;
}#concurso-temp .jugadore-mob {
    display: none;
}#concurso-temp #form-field::placeholder {
    font-size: 1.042vw;
}
@media (max-width:768px) {


.l-button{
    background: #B70D1D;
    color: white;
    border: none !important;
    border-radius: 0px !important;
}
.l-button:hover{
    background: #a00b1a;
}

.Mensaje_Error{
    margin-top: 1vw;
    background-color: #a00b1a;
    color: white;
    width: 100%;
    height: 10vw;
    text-align: center;
    padding-top: 3vw;
    margin-bottom: 10vw;
    font-size: 3vw;
}
.Mensaje_Suc{
    margin-top: 1vw;
    background-color: #117221;
    color: white;
    width: 100%;
    height: 10vw;
    text-align: center;
    padding-top: 3vw;
    margin-bottom: 10vw;
    font-size: 3vw;
}



#concurso-temp .body-concurso{
    padding-top: 24vw;
     background-image: url('../assets/fondiu-bases.png'), url('../assets/cuernifero-2.png');
    background-size: 140%, 100%;
    background-position-y: 24vw, bottom;
}#concurso-temp .header-concurso {
    height: 78.986vw;
    padding-top: 28.019vw;
}#concurso-temp .header-concurso h5 {
    font-size: 3.623vw;
    line-height: 4.106vw;
    width: 50vw;
    text-align: center;
    margin-bottom: 4vw;
}#concurso-temp .header-concurso h1 {
    font-size: 12.077vw;
    width: 90%;
}#concurso-temp .header-concurso a {
    font-family: 'Rajdhani';
    margin-top: 4.5vw;
}#concurso-temp .form-body {
    align-items: center;
    flex-direction: column;
}#concurso-temp .kit-img {
    width: 97.101vw;
}#concurso-temp .form-cont {
    width: 78.744vw;
}#concurso-temp .titulo-form {
    font-size: 4.348vw;
    line-height: 4.348vw;
    margin-top: 14.734vw;
    margin-bottom: 16.908vw;
    text-align: center;
}#concurso-temp .field-cont p {
    font-size: 4.348vw;
}#concurso-temp #form-field {
    width: 100%;
    height: 18.841vw;
    font-size: 4.348vw;
    padding: 0 6.448vw;
}#concurso-temp #form-field::placeholder {
    font-size: 4.348vw;
}#concurso-temp .field-cont {
    margin-bottom: 8.135vw;
}#concurso-temp .lastb {
    width: 100%;
    padding: 0 0 31.401vw;
}#concurso-temp .l-button {
    width: 100%;
    padding: 6.823vw 4.95vw;
    height: 20.773vw;
    /* display: flex; */
    justify-content: space-between;
}#concurso-temp .l-button:hover {
    background-position-x: 57.521vw;
    padding: 1.823vw 7.95vw;
}#concurso-temp .bases-body {
    padding: 0;
    flex-direction: column;
}#concurso-temp .titulo-bases br {
    display: none;
}#concurso-temp .bases-text .titulo-bases {
    font-size: 21.739vw;
    line-height: 78%;
    width: fit-content;
    margin: 0 auto 21.981vw;
}#concurso-temp .bases-dots {
    margin: 0 auto 6.813vw;
    width: 82.609vw;
}#concurso-temp .dotiteh {
    font-size: 5.797vw;
    width: 12.077vw;
    height: 12.077vw;
    border-radius: 14.077vw;
    margin-right: 5.556vw;
}#concurso-temp .bases-dots p {
    font-size: 3.865vw;
    line-height: 4.865vw;
    width: 64.021vw;
}#concurso-temp .jugadore-mob {
    margin-top: 13.285vw;
    width: auto;
    height: 136.232vw;
    display: block;
}#concurso-temp .jugadore {
    display: none;
}
}
</style>